import React from 'react'
import styled from 'styled-components'
import {
  Route,
  Redirect,
  Switch,
  HashRouter as Router,
  useHistory,
  RouteComponentProps,
} from 'react-router-dom'

const types = ['ABEmax', 'Target-AID', 'BE4max', 'ABE8e']

const mapTypeToDescription: any = {
  ABEmax: `This model was trained on an average editing efficiency of 20.9% (ABEmax). Total editing rates may vary depending on your experimental setup.`,
  BE4max: `This model was trained on an average editing efficiency of 14.5% (CBE4max). Total editing rates may vary depending on your experimental setup`,
  ABE8e: `This model was trained on an average editing efficiency of 24.9% (ABE8e). Total editing rates may vary depending on your experimental setup`,
  'Target-AID': `This model was trained on an average editing efficiency of 10.6% (Target-AID). Total editing rates may vary depending on your experimental setup`,
}

const mapToHex = (a: number): Record<string, string> => ({
  A: `rgba(236, 186, 175, ${a})`,
  T: `rgba(185, 217, 239, ${a})`,
  C: `rgba(243, 233, 166, ${a})`,
  G: `rgba(181, 216, 206, ${a})`,
})

const complement: Record<string, string> = {
  A: 'G',
  C: 'T',
  G: 'A',
  T: 'C',
}

const BORDER = 'rgba(0,0,0,0.1)'

const OuterWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px;
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  label {
    margin-right: 8px;
    font-size: 14px;
    font-weight: bold;
  }

  div {
    padding: 16px;

    &:last-child {
      border-left-width: 0;
    }
  }
`

const Results = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 32px;
  align-items: center;
  width: 100%;
`

const NumResults = styled.p`
  position: absolute;
  bottom: -24px;
  cursor: pointer;
  font-size: 12px;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 16px 0;
  border-right: 1px solid ${BORDER};
  border-left: 1px solid ${BORDER};
  width: 640px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${BORDER};

  &:first-child {
    border-top: 1px solid ${BORDER};
  }
`

const Item = styled.div`
  width: 32px;
  border-right: 1px solid ${BORDER};
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-right-width: 0;
  }
`

const Title = styled.p`
  padding: 8px;
  font-size: 13px;
  width: 100%;
  opacity: 0.8;
  font-style: italic;
`

const ClearResults = styled.p`
  position: absolute;
  bottom: -24px;
  right: 16px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
`

const Logo = styled.img`
  width: 144px;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
`

const Textarea = styled.textarea`
  width: 800px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`

const Button = styled.button`
  border: 0;
  width: 144px;
  height: 42px;
  border-radius: 4px;
  line-height: 42px;
  text-align: center;
  color: white;
  cursor: pointer;
  transition: all ease 0.7s;
  background-color: #0494db;
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
  font-family: Helvetica;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }
`

const Description = styled.p`
  font-size: 16px;
  margin: 16px 0 32px 0;
  text-align: center;
  max-width: 600px;
`

const Note = styled.p`
  font-size: 12px;
  opacity: 0.8;
  text-align: center;
  max-width: 600px;
  margin: 48px auto 0 auto;
  opacity: 0.7;
`

const Loading = styled.p`
  margin: 32px auto 0 auto;
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  text-align: center;
  opacity: 0.5;
  max-width: 700px;
`

const TableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const Rank = styled.p`
  font-size: 32px;
  opacity: 0.3;
  margin-right: 32px;
  padding-top: 16px;
`

const Types = styled.div`
  display: flex;
  align-items: center;
`

const TypeItem = styled.p`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 8px;
`

const ColumnTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  bottom: -24px;
  left: 0;
`

const MotiveWrapper = styled.div``

const LeftWrapper = styled.div``

const ProbList = styled.div`
  margin-top: ${88 + 48}px;
`

const ProbItem = styled.div`
  height: 49px;
  width: 100%;
  padding-right: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ProbLabel = styled.p`
  font-size: 12px;
`

const TotalPercentage = styled.p`
  text-align: left;
  width: 100%;
  margin: -8px auto 32px auto;
  font-size: 12px;
  opacity: 0.7;
  width: 590px;
`

type Data = {
  guides: Array<{ id: string; guide: string }>
  motive: Array<[number, string, string, string, number]>
  predictions: Array<{ id: string; index: number; probability: number }>
}

const Page = (props: RouteComponentProps) => {
  const [type, setType] = React.useState('ABEmax')
  const [bases, setBases] = React.useState('')
  const [data, setData] = React.useState<Data>()
  const [loading, setLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  const isA = props.location.pathname !== '/bystander'

  React.useEffect(() => {
    setData(undefined)
    setBases('')
  }, [isA])

  const title = isA ? 'BE-DICT per-base' : 'BE-DICT bystander'

  const renderTables = (d: Data) => {
    const formatted = d.guides
      .map((g) => ({
        ...g,
        items: d.predictions.filter((p) => p.id === g.id),
      }))
      .map((g) => ({
        ...g,
        rank: g.items.reduce(
          (acc, i) => (i.probability > acc ? i.probability : acc),
          0,
        ),
      }))
      .sort((a, b) => (a.rank > b.rank ? -1 : 1))

    return (
      <Results>
        {formatted.map((guide, index) => {
          const motiveRows = (data?.motive || []).filter((d) => d[2] === guide.guide)
            .sort((a, b) => (a[4] > b[4] ? -1 : 1))

          const total =
            motiveRows?.slice(1).reduce((acc, i) => acc + Number(i[4]), 0) || 0
          console.log(motiveRows, total)
          return (
            <>
              <TableWrapper>
                <LeftWrapper>
                  {isA && <Rank>{guide.rank.toFixed(3)}</Rank>}
                  {!isA && (
                    <ProbList>
                      {motiveRows?.slice(1).map((r, i) => (
                        <ProbItem key={i}>
                          <ProbLabel>{(r[4] * 100).toFixed(1)} %</ProbLabel>
                        </ProbItem>
                      ))}
                    </ProbList>
                  )}
                </LeftWrapper>
                <Table key={guide.id}>
                  <Row style={{ width: '100%' }}>
                    <Title>
                      sgRNA-{index + 1}: {guide.guide}
                    </Title>
                  </Row>
                  <Row>
                    {guide.guide
                      .split('')

                      .map((c, k) => (
                        <Item key={k} style={{ height: 36 }}>
                          <p style={{ fontSize: 12, opacity: 0.7 }}>{k + 1}</p>
                        </Item>
                      ))}
                  </Row>
                  <Row>
                    {guide.guide.split('').map((c, k) => (
                      <Item
                        key={k}
                        style={{ height: 48, backgroundColor: mapToHex(1)[c] }}
                      >
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>{c}</p>
                      </Item>
                    ))}
                  </Row>
                  {isA ? (
                    <Row>
                      {guide.guide.split('').map((c, k) => {
                        const p = guide.items.find((i) => i.index === k)
                        return (
                          <Item
                            key={k}
                            style={{
                              backgroundColor: mapToHex(p ? p.probability : 0)[
                                complement[guide.guide[k]]
                              ],
                              height: 48,
                            }}
                          >
                            <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                              {p ? `${p.probability.toFixed(2)}` : ''}
                            </p>
                          </Item>
                        )
                      })}
                    </Row>
                  ) : (
                    <MotiveWrapper>
                      {motiveRows?.slice(1).map((r, k) => (
                        <Row key={k} style={{ borderTop: 0 }}>
                          {r[3].split('').map((c, i) => (
                            <Item
                              key={i}
                              style={{
                                backgroundColor:
                                  guide.guide[i] !== c
                                    ? mapToHex(1)[complement[guide.guide[i]]]
                                    : 'none',

                                height: 48,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: guide.guide[i] !== c ? 14 : 12,
                                  fontWeight:
                                    guide.guide[i] !== c ? 'bold' : 'normal',
                                  opacity: guide.guide[i] !== c ? 1 : 0.3,
                                }}
                              >
                                {c}
                              </p>
                            </Item>
                          ))}
                        </Row>
                      ))}
                    </MotiveWrapper>
                  )}
                </Table>
              </TableWrapper>
              {!isA && (
                <TotalPercentage>
                  Fraction of outcomes with base editing activity above 1%:{' '}
                  <b>{(total * 100).toFixed(1)}%</b>
                </TotalPercentage>
              )}
            </>
          )
        })}
      </Results>
    )
  }

  // CATGGTGATGCGGTTTTGGCAGTACATCAATGGGCGTGGATAGCGGTTTGACTCACGGGGATTTCCAAGTCTCCACCCCATTGACGTCAATGGGAGTTTGTTTTGGCACCAAAATCAACGGGACTTTCCAAAATGTCGTAACAACTCCGCCCCATTGACGCAAATGGGCGGTAGGCGTGTACGGTGGGAGGTCTATATAAGCAGAGCTCTCTGGCTAACTACCGGTGCC

  const getData = async () => {
    setLoading(true)
    setHasError(false)

    // http://localhost:3003

    try {
      const res = await fetch('/api/compute', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ bases, type }),
      }).then((r) => r.json())
      if (res.guides && res.predictions) {
        setData(res)
        setLoading(false)
      } else {
        throw new Error('bad data')
      }
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  return (
    <div className="App">
      <OuterWrapper>
        <InputWrapper>
          <Logo src={'/bedict_logo.png'} />
          <h2 style={{ marginBottom: 0 }}>{title}</h2>
          <Description>
            Paste or type the DNA sequence you want to edit and specify which
            machine learning model to use by selecting one of the four base
            editors. The input DNA sequence is searched for ‘NGG’ PAMs
            automatically.
          </Description>

          <>
            <Types
              style={
                !data
                  ? {}
                  : {
                      filter: 'grayscale(100%)',
                      pointerEvents: 'none',
                    }
              }
            >
              {types.map((i) => (
                <TypeItem
                  key={i}
                  style={{
                    backgroundColor: i === type ? '#0494db' : 'transparent',
                    color: i === type ? 'white' : 'black',
                  }}
                  onClick={() => setType(i)}
                >
                  {i}
                </TypeItem>
              ))}
            </Types>

            <Textarea
              onChange={(e) => setBases(e.target.value)}
              value={bases}
              maxLength={400}
              disabled={!!data}
              placeholder="CATGGTGATGCGGTTTTGGCAGTA..."
            ></Textarea>
            {!loading && !data && <Button onClick={getData}>Submit</Button>}
          </>
          {data && <NumResults>{data.guides.length} guides found</NumResults>}
          {data && isA && <ColumnTitle>Outcome Frequency (0 - 1)</ColumnTitle>}
          {data && (
            <ClearResults
              onClick={() => {
                setData(undefined)
              }}
            >
              Clear Results
            </ClearResults>
          )}
        </InputWrapper>
      </OuterWrapper>

      {data && (
        <Note>
          {!isA
            ? mapTypeToDescription[type]
            : ` Note: The values represent probability scores for each respective base
          to be edited. Total editing rates may vary depending on your
          experimental setup.`}
        </Note>
      )}

      {hasError && (
        <Loading>
          An error has occured. Please make sure your input sequence is min. 23
          nucleotides long and has a PAM (NGG). "AGCT" letters are accepted.
        </Loading>
      )}

      {loading && <Loading>Computing your prediction scores... </Loading>}

      {data && renderTables(data)}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 16,
          right: 32,
        }}
      >
        <a
          onClick={() => {
            props.history.push('/bystander')
          }}
        >
          Bystander
        </a>
        <a
          onClick={() => {
            props.history.push('/per-base')
          }}
        >
          Per-base
        </a>
      </div>
    </div>
  )
}

export const App = () => (
  <Router>
    <Switch>
      <Route path="/per-base" component={Page} />
      <Route path="/bystander" component={Page} />
      <Redirect to="/bystander" />
    </Switch>
  </Router>
)
